import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MainLayout from "src/layouts/Main";

import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  layoutProps?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout {...route.layoutProps}>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    guard: AuthGuard,
    layout: MainLayout,
    path: "/:buildingId/contact",
    component: lazy(() => import("src/pages/ContactPage")),
    layoutProps: { estimationButton: false },
  },
  {
    layout: MainLayout,
    layoutProps: { estimationButton: true },
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: "/404",
        component: lazy(() => import("src/pages/NotFoundView")),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: "/unauthorised",
        component: lazy(() => import("src/pages/unauthorised")),
      },

      {
        exact: true,
        path: "/:buildingId",
        guard: AuthGuard,
        component: lazy(() => import("src/pages/priceStatistics")),
      },
      {
        layout: MainLayout,
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  {
    layout: MainLayout,
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
