import get from "lodash/get";
export const FETCH_BUILDING = "FETCH_BUILDING";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const FETCH_TRANSACTIONS_END = "FETCH_TRANSACTIONS_END";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const SET_GEO_TYPE = "SET_GEO_TYPE";
export const SET_GEO_TYPE_OPTIONS = "SET_GEO_TYPE_OPTIONS";
export const SET_SORT_TYPE = "SET_SORT_TYPE";
export const SET_MAP_PRICES = "SET_MAP_PRICES";
export const SET_PRICES = "SET_PRICES";
export const SET_PRICES_END = "SET_PRICES_END";
export const SET_SELECTED_TRANSACTION = "SET_SELECTED_TRANSACTION";
export const SET_FROM_TRANSACTION = "SET_FROM_TRANSACTION";

const nantesGrandsQuartiersRenaming = {
  "4410901": "Centre-Ville",
  "4410903": "Canclaux - Mellinet - Zola",
  "4410907": "Sainte Thérèse - Beauséjour - Rond-point de Vannes- Procé",
  "4410902": "Chantenay - Sainte Anne",
  "4410909":
    "Rond-point-Route de Paris - La Beaujoire - St-Joseph - L'Eraudière",
  "4410910": "Doulon - Vieux Doulon -Bottière",
  "4410906": "Ile de Nantes - République - Beaulieu",
  "4410904": "Saint-Pasquier - St-Felix -Ront-point de Rennes ",
  "4410905": "Tortière - St Donatien - Saint-Clément - Chalâtres",
  "4410908": "Pont du Cens - Petit port - Boissière",
  "4410911": "Saint-Jacques - CHU",
};

// Changing grand quartier codes for Thierry Immobilier
const formatGrandQuartier = (gq: GrandQuartier) => {
  const gqCodes = Object.keys(nantesGrandsQuartiersRenaming);
  if (gqCodes.includes(gq.grandQuartierCode)) {
    gq["grandQuartier"] = get(
      nantesGrandsQuartiersRenaming,
      gq.grandQuartierCode,
      gq.grandQuartier
    );
  }
  return gq;
};

export const fetchBuilding = (building: any) => ({
  type: FETCH_BUILDING,
  payload: building,
});

export const fetchContact = (contact: Contact) => ({
  type: FETCH_CONTACT,
  payload: contact,
});

export const setViewType = (searchType: Store["viewType"]) => ({
  type: SET_VIEW_TYPE,
  payload: searchType,
});

export const fetchTransactions = (
  transactionsApiProps: TransactionsApiProps
) => ({
  type: FETCH_TRANSACTIONS,
  payload: transactionsApiProps,
});

export const setGeoType = (geoType: Store["geoType"]) => {
  return {
    type: SET_GEO_TYPE,
    payload: geoType,
  };
};
export const setGeoTypeOptions = (geoType: Store["geoTypeOptions"]) => {
  return {
    type: SET_GEO_TYPE_OPTIONS,
    payload: geoType,
  };
};
export const setSortType = (sortType: SortType) => {
  return {
    type: SET_SORT_TYPE,
    payload: sortType,
  };
};
export const setMapPrices = (mapPrices: MapPrices) => {
  const updateGrandQuartier = mapPrices.grandQuartier
    ? mapPrices.grandQuartier.map((element) => {
        return formatGrandQuartier(element);
      })
    : [];

  return {
    type: SET_MAP_PRICES,
    payload: {
      ...mapPrices,
      grandQuartier: updateGrandQuartier,
    },
  };
};
export const setPrices = (pricesQuery: {
  latitude: number;
  longitude: number;
  cityCode: string;
}) => {
  return {
    type: SET_PRICES,
    payload: pricesQuery,
  };
};

export const setPricesEnd = (payload: Prices) => {
  return {
    type: SET_PRICES_END,
    payload: {
      ...payload,
      ...(payload.grandQuartier && {
        grandQuartier: formatGrandQuartier(payload.grandQuartier),
      }), // Updating Grand quartier format
    },
  };
};

export const setSelectedTransaction = (
  building: Store["selectedTransaction"]
) => ({
  type: SET_SELECTED_TRANSACTION,
  payload: building,
});

export const setFromTransaction = (from: Store["from"]) => ({
  type: SET_FROM_TRANSACTION,
  payload: from,
});
