import apiClient from "../utils/apiClient";

export const geo = (params: any): Promise<any> => {
  return apiClient.get(`/prices`, { params }).then(({ data }) => data);
};

export const getMapPrices = (params: {
  latitude: number;
  longitude: number;
  cityCode: string;
}): Promise<MapPrices> => {
  return apiClient
    .get(`/prices/mapPrices`, { params })
    .then(({ data }) => data);
};
export const prices = (params: {
  latitude: number;
  longitude: number;
  cityCode: string;
}): Promise<Prices> => {
  return apiClient.get(`/prices/point`, { params }).then(({ data }) => data);
};
