import {
  FETCH_CONTACT,
  SET_VIEW_TYPE,
  FETCH_TRANSACTIONS_END,
  FETCH_BUILDING,
  SET_GEO_TYPE,
  SET_GEO_TYPE_OPTIONS,
  SET_SORT_TYPE,
  SET_MAP_PRICES,
  SET_PRICES_END,
  SET_SELECTED_TRANSACTION,
  SET_FROM_TRANSACTION,
} from "src/actions";

const initialState: Store = {
  building: null,
  contact: null,
  prices: null,
  mapPrices: null,
  viewType: "prices",
  sort: "mostRecent",
  geoType: "city",
  geoTypeOptions: null,
  transactions: [],
  totalTransactions: 0,
  selectedTransaction: null,
  from: 0,
  size: 100,
};

const generalReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case FETCH_BUILDING:
      return { ...state, building: action.payload };
    case FETCH_CONTACT:
      return { ...state, contact: action.payload };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_GEO_TYPE:
      return {
        ...state,
        geoType: action.payload,
      };
    case SET_GEO_TYPE_OPTIONS:
      return {
        ...state,
        geoTypeOptions: action.payload,
      };

    // renitialize Zéro
    case SET_SORT_TYPE:
      return {
        ...state,
        sort: action.payload,
        from: 0,
      };

    case FETCH_TRANSACTIONS_END:
      return {
        ...state,
        transactions: action.payload.results,
        totalTransactions: action.payload.total,
      };
    case SET_MAP_PRICES:
      return { ...state, mapPrices: action.payload };
    case SET_PRICES_END:
      return { ...state, prices: action.payload };
    case SET_SELECTED_TRANSACTION:
      return { ...state, selectedTransaction: action.payload };
    case SET_FROM_TRANSACTION:
      return { ...state, from: action.payload };
    default:
      return state;
  }
};

export default generalReducer;
