import { call, put, takeLatest } from "redux-saga/effects";

import { FETCH_TRANSACTIONS, FETCH_TRANSACTIONS_END } from "src/actions";
import { fetchTransactions as apiFetchTransactions } from "src/api/transaction";
import { fetchTransactions as fetchTransactionsAction } from "src/actions";

function* fetchTransactions(
  action: ReturnType<typeof fetchTransactionsAction>
) {
  try {
    // @ts-ignore
    const transactions: any = yield call(apiFetchTransactions, action.payload);

    yield put({ type: FETCH_TRANSACTIONS_END, payload: transactions });
  } catch (e) {
    console.log(e);
    yield put({ type: FETCH_TRANSACTIONS_END, error: e });
  }
}

const sagaArray = [takeLatest(FETCH_TRANSACTIONS, fetchTransactions)];

export default sagaArray;
