import React from "react";
import { useSelector } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Box, Toolbar } from "@material-ui/core";

import EstimateButton from "src/components/EstimateButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface Props {
  estimationButton: boolean;
}

const MenuAppBar = ({ estimationButton }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { building } = useSelector((store: Store) => store);

  if (!building) return null;

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          background: building.agency.appBarWhite
            ? "white"
            : theme.palette.primary.main,

          boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 20%)",
        }}
      >
        <Toolbar variant="dense">
          <Box flexGrow="1" display="flex">
            <img
              src={building.agency.logo}
              height={building.agency.company.id === 1 ? "30px" : "25px"}
              alt="logo"
              style={{ marginTop: "3px" }}
            />
          </Box>

          {estimationButton && <EstimateButton />}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MenuAppBar;
