import { call, put, takeLatest } from "redux-saga/effects";

import { SET_PRICES, SET_PRICES_END } from "src/actions";
import { prices as apiPrices } from "src/api/prices";
import {
  setPrices as setPricesAction,
  setPricesEnd as setPricesActionEnd,
} from "src/actions";

function* setPrices(action: ReturnType<typeof setPricesAction>) {
  try {
    const payload: Prices = yield call(apiPrices, action.payload);
    yield put(setPricesActionEnd(payload));
  } catch (e) {
    yield put({ type: SET_PRICES_END, error: e });
  }
}

const sagaArray = [takeLatest(SET_PRICES, setPrices)];

export default sagaArray;
