import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Button } from "@material-ui/core";

import SuccessMessage from "./SuccessMessage";
import EstimationForm from "./EstimationForm";
import Modal from "../Modal";

const EstimateButton = () => {
  const { building } = useSelector((store: Store) => store);

  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const [sending, setSending] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSetSending = (isSending: boolean) => {
    setSending(isSending);
  };
  // Open Contact when contactModal=true query params is set
  const searchParams = new URLSearchParams(window.location.search);
  const contactModal = searchParams.get("contactModal");

  useEffect(() => {
    if (contactModal && contactModal === "true") {
      setOpen(true);
    }
  }, [contactModal]);

  const handleClose = () => {
    // Close Modal only if contact Modal is not to False

    if (contactModal !== "true") {
      setOpen(false);
    }
  };

  const onFormSubmit = () => {
    handleClose();
    handleOpenConfirmation();
  };
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  if (!building) return null;

  return (
    <div className="estimate-button">
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        Estimer mon bien
      </Button>

      <Modal
        title="RENDEZ-VOUS D'ESTIMATION"
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        loading={sending}
      >
        <EstimationForm
          handleSetSending={handleSetSending}
          onSuccess={onFormSubmit}
        />
      </Modal>

      <Modal
        title="DEMANDE DE CONTACT CONFIRMÉE"
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleOpen={() => setOpenConfirmation(true)}
        loading={sending}
        cancelButton={false}
        confirmText="OK"
        confirmCallBack={() => setOpenConfirmation(false)}
      >
        <SuccessMessage />
      </Modal>
    </div>
  );
};

export default EstimateButton;
