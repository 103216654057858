import { Box, Typography, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const SuccessMessage = () => {
  const theme = useTheme();

  return (
    <Box maxWidth="400px" textAlign="center">
      <CheckCircleIcon
        style={{ fontSize: "100px", color: theme.palette.success.main }}
      />
      <Typography variant="body1" color="textPrimary">
        Votre demande a bien été prise en compte. Nous allons vous recontacter
        sous peu.
      </Typography>
    </Box>
  );
};

export default SuccessMessage;
