import React from "react";
import Button from "@material-ui/core/Button";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

interface Props {
  children: React.ReactNode;
  title: string;
  open: boolean;
  handleClose: any;
  handleOpen: any;
  loading?: boolean;
  confirmCallBack?: any;
  cancelText?: string;
  confirmText?: string;
  cancelButton?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Modal = ({
  children,
  title,
  open,
  handleClose,
  confirmCallBack,
  loading,
  confirmText,
  cancelText,
  cancelButton = true,
}: Props) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {cancelButton && (
          <Button onClick={handleClose} color="secondary">
            {cancelText ? cancelText : "Annuler"}
          </Button>
        )}

        <Button
          disabled={loading}
          onClick={() => {
            if (confirmCallBack) confirmCallBack();
          }}
          variant="contained"
          color="secondary"
          form="hook-form" // To link to form
          type="submit"
        >
          {confirmText ? confirmText : "Confirmer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
