import apiClient from "../utils/apiClient";

export const fetchTransactions = (
  searchParams: TransactionsApiProps
): Promise<TransactionApiResults> => {
  const urlParams = { ...searchParams, apartment: "true" };
  return apiClient
    .get("/transactions", { params: urlParams })
    .then(({ data }) => data);
};
