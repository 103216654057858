import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import GoogleAnalytics from "src/components/GoogleAnalytics";

import { ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { createTheme } from "./theme";
import routes, { renderRoutes } from "./routes";

function App() {
  const { building } = useSelector((store: Store) => store);

  const theme = building ? createTheme(building.agency.theme) : createTheme("");

  return (
    <>
      <MaterialThemeProvider theme={theme.materialUITheme}>
        <ThemeProvider theme={theme.theme}>
          <BrowserRouter>
            <GoogleAnalytics />
            {renderRoutes(routes)}
          </BrowserRouter>{" "}
        </ThemeProvider>
      </MaterialThemeProvider>
    </>
  );
}

export default App;
