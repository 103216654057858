import React from "react";
import type { FC, ReactNode } from "react";

import AppBar from "src/components/AppBar";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 48px)",
      height: "1px",
      marginTop: "48px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(100vh - 48px)",
      height: "1px",
      marginTop: "48px",
    },
  },
}));

interface MainLayoutProps {
  children?: ReactNode;
  estimationButton: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ children, estimationButton }) => {
  const classes = useStyles();

  return (
    <div>
      <AppBar estimationButton={estimationButton} />
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default MainLayout;
