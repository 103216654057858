import React from "react";
import type { FC, ReactNode } from "react";

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  return <>{children}</>;
};

export default GuestGuard;
