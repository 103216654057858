import axios from "axios";

const { REACT_APP_BACKEND_URL } = process.env;

const apiClient = axios.create({
  baseURL: REACT_APP_BACKEND_URL,
  headers: {},
});

export default apiClient;
