import apiClient from "../utils/apiClient";

// Returns building data results
export const getBuilding = (
  buildingId: string | number,
  token: string | number
) =>
  apiClient
    .get(`/buildings/${buildingId}?token=${token}`)
    .then(({ data }) => data);
