import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import parsePhoneNumberFromString from "libphonenumber-js";

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";

import { createContact } from "src/api/contact";

const validationSchema = yup.object().shape({
  firstname: yup.string().nullable(),
  lastname: yup.string().required("Champs obligatoire"),
  email: yup
    .string()
    .email("Renseignez un email valide")
    .required("Champs obligatoire"),
  telephone: yup
    .string()
    .test("valid-phone", "Rentrez un numéro valide", (value: any) => {
      const phoneNumeber = parsePhoneNumberFromString(value);
      if (phoneNumeber && phoneNumeber.isValid()) {
        return true;
      } else {
        return false;
      }
    })
    .required("Champs obligatoire"),
});

const normalizePhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumberFromString(value, "FR");
  if (!phoneNumber) {
    return value;
  }

  return phoneNumber.formatInternational();
};

const renderList = (list: string[]) => {
  return list.map((element, index) => (
    <List dense={true} key={index} style={{ padding: 0 }}>
      <ListItem disableGutters>
        <ListItemIcon style={{ minWidth: 35 }}>
          <CheckIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={element} />
      </ListItem>
    </List>
  ));
};

interface Props {
  onSuccess: () => void;
  handleSetSending: (sending: boolean) => void;
}

const EstimateButton = ({ onSuccess, handleSetSending }: Props) => {
  const { building } = useSelector((store: Store) => store);

  const params = new URLSearchParams(window.location.search);

  const defaultValues = {
    firstname: params.get("firstname") || "",
    lastname: params.get("lastname") || "",
    email: params.get("email") || "",
    telephone: params.get("telephone") || "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const [error, setError] = useState("");

  useEffect(
    () => {
      reset(defaultValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultValues.email,
      defaultValues.firstname,
      defaultValues.lastname,
      reset,
    ]
  );

  if (!building) return null;

  const list = [
    `Réalisé par un expert ${building.agency.company.name}`,
    `Dossier d'estimation précis et complet`,
    "Gratuit et sans engagement",
    "Connaissance fine de votre immeuble",
  ];

  const onSubmit = async (contact: Contact) => {
    try {
      handleSetSending(true);
      await createContact({
        ...contact,
        buildingId: building.id,
        address: building.address,
        zipcode: building.zipcode,
        city: building.city,
        buildingUrl: window.location.href.replace("/contact", ""), // replace contact if launched
      });
      onSuccess();
      handleSetSending(false);
    } catch (e: any) {
      setError(e.message);
      handleSetSending(false);
    }
  };

  const agencyContact = () => {
    if (building?.users.length === 1) {
      return building.users[0];
    } else if (building && building?.users.length > 1) {
      return building.users.filter(
        (user) => user.type === "agency_director"
      )[0];
    } else {
      return null;
    }
  };
  const agent = agencyContact();

  return (
    <form
      id="hook-form" // To link to submit button
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Avatar
            style={{ height: "70px", width: "70px", marginBottom: "20px" }}
            //  alt={building?.salesRepName}
            src={agent ? agent.photo : ""}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="h5" color="textPrimary">
            {agent?.firstname} {agent?.lastname.toLocaleUpperCase()}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginBottom: "20px" }}
          >
            {agent?.title}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Bonjour ! Afin de pouvoir planifier votre estimation, pouvez-vous me
            communiquer vos coordonnées afin que mon équipe puisse vous rappeler
            dans les meilleurs délais ?
          </Typography>
          <Box margin="20px 0"> {renderList(list)}</Box>
          <Box margin="30px 0" textAlign="center">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      color="secondary"
                      label="Prénom"
                      type="text"
                      fullWidth
                      required={true}
                      error={!!errors?.firstname}
                      helperText={errors?.firstname?.message}
                    />
                  )}
                  name="firstname"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      color="secondary"
                      label="Nom"
                      type="text"
                      fullWidth
                      required={true}
                      error={!!errors?.lastname}
                      helperText={errors?.lastname?.message}
                    />
                  )}
                  name="lastname"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      color="secondary"
                      label="Email"
                      type="email"
                      fullWidth
                      required={true}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      color="secondary"
                      label="Téléphone"
                      type="text"
                      fullWidth
                      required={true}
                      error={!!errors?.telephone}
                      helperText={errors?.telephone?.message}
                      onChange={(e) => {
                        const cleanedTel = normalizePhoneNumber(e.target.value);
                        e.target.value = cleanedTel;
                        setValue("telephone", cleanedTel, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                  name="telephone"
                  control={control}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" style={{ textAlign: "left" }}>
                    <AlertTitle>Erreur</AlertTitle>
                    Une erreur s'est produite. Veuillez réessayer
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default EstimateButton;
