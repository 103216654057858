import {
  createTheme as createMuiTheme,
  ThemeOptions,
  Theme,
} from "@material-ui/core";
import typography from "./typography";

import get from "lodash/get";

export const createTheme = (
  selectedTheme: string
): { materialUITheme: Theme; theme: ThemeOptions } => {
  const syndicOne: ThemeOptions = {
    palette: {
      primary: {
        main: "#00ae93",
      },
      secondary: {
        main: "#e62958",
      },
      text: {
        primary: "#222222",
      },
      info: {
        main: "#002a9b",
      },
    },
  };

  const sergic: ThemeOptions = {
    palette: {
      primary: {
        main: "#00b3ff",
      },
      secondary: {
        main: "#e62958",
      },
      text: {
        primary: "#222222",
      },
      info: {
        main: "#002a9b",
      },
    },
  };

  const oralia: ThemeOptions = {
    palette: {
      primary: {
        main: "#006139",
      },
      secondary: {
        main: "#ef527a",
      },
      text: {
        primary: "#222222",
      },
      info: {
        main: "#002a9b",
      },
    },
  };

  const atrium: ThemeOptions = {
    palette: {
      primary: {
        main: "#ca213c",
      },
      secondary: {
        main: "#e62958",
      },
      text: {
        primary: "#222222",
      },
      info: {
        main: "#002a9b",
      },
    },
  };

  const themes = { sergic, atrium, oralia, syndicOne };

  const baseOptions: ThemeOptions = {
    palette: {
      primary: {
        main: "#006363",
      },
      secondary: {
        main: "#275fcd",
      },
      text: {
        primary: "#222222",
      },
      info: {
        main: "#002a9b",
      },
    },
    typography,
    overrides: {
      MuiDialogContent: {
        root: {
          background: "#FFFFFF !important",
        },
      },
      MuiButton: {
        containedPrimary: {
          color: "#FFFFFF",
        },
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
        },
      },
      MuiCard: {
        root: {
          margin: "10px 0px",
        },
      },
      MuiCardContent: {
        root: {
          paddingTop: "40px !important",
          paddingBottom: "40px !important",
        },
      },
      MuiCssBaseline: {
        "@global": {
          html: {
            WebkitFontSmoothing: "auto",
          },
        },
      },
    },
  };
  const customTheme: any = get(themes, selectedTheme, {});

  const theme = { ...baseOptions, ...customTheme };
  const materialUITheme = createMuiTheme({ ...baseOptions, ...customTheme });

  return { materialUITheme, theme };
};
