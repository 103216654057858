import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import type { ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { fetchBuilding } from "src/actions";
import { getBuilding } from "src/api/building";
import { fetchContact } from "src/actions";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const [authorized, setAuthorised] = useState<null | boolean>(null);
  const [buildingInfo, setBuildingInfo] = useState<null | Building>(null);
  const dispatch = useDispatch();
  const { buildingId } = useParams<{
    buildingId: string;
  }>();

  useEffect(() => {
    async function checkBuildingId() {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get("token");
      const email = searchParams.get("email");
      const firstname = searchParams.get("firstname");
      const lastname = searchParams.get("lastname");

      if (buildingId && token) {
        try {
          const building = await getBuilding(buildingId, token);

          setBuildingInfo(building);
          setAuthorised(true);
          dispatch(fetchBuilding(building));
          dispatch(fetchContact({ email, firstname, lastname }));
        } catch (e) {
          setAuthorised(false);
        }
      } else {
        setAuthorised(false);
      }
    }

    checkBuildingId();
  }, [dispatch, buildingId]);

  if (authorized === false) {
    return <Redirect to="/unauthorised" />;
  }

  // Added SubTheme to
  return (
    <>
      {buildingInfo && (
        <Helmet>
          <link
            rel="shortcut icon"
            type="image/jpg"
            href={buildingInfo.agency.favicon}
          />
        </Helmet>
      )}

      {children}
    </>
  );
};

export default AuthGuard;
